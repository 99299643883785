<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.config") }}
      <v-spacer></v-spacer>
      <v-btn
        small
        color="success"
        outlined
        @click="save"
        class="mr-2"
        :disabled="isDisabledBtn"
      >
        {{ $t("labels.save") }}
      </v-btn>
      <v-btn small color="error" outlined @click="cancel">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="6" md="4">
          <v-text-field
            v-model="name"
            class="c-input-xs"
            label="Tên cấu hình"
            placeholder="Tên cấu hình"
            dense
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="6" md="4">
          <!-- <v-text-field v-model="code" class="c-input-xs" label="Mã cấu hình" placeholder="Mã cấu hình" dense
                        single-line outlined hide-details></v-text-field> -->
        </v-col>
      </v-row>

      <div>
        <v-simple-table class="table-padding-2">
          <template v-slot:default>
            <thead class="v-data-table-header">
              <tr>
                <th
                  role="columnheader"
                  class="text-center"
                  style="min-width: 120px"
                >
                  Phần việc
                </th>
                <th
                  role="columnheader"
                  class="text-center"
                  style="min-width: 120px"
                >
                  Loại giới hạn
                </th>
                <th role="columnheader" class="text-center">
                  Thời gian tối thiểu
                </th>
                <th role="columnheader" class="text-center">Min. Chưa xử lý</th>
                <th role="columnheader" class="text-center">Min. Cần xử lý</th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-center" v-for="item in items" :key="item.id">
                <td>{{ $t(`labels.work_type_${item.work_type}`) }}</td>
                <td>{{ item.type == 1 ? "Sản phẩm" : "Đơn hàng" }}</td>
                <td>
                  <v-text-field
                    v-model="item.wait_time"
                    class="c-input-small c-input-append-text"
                    dense
                    single-line
                    outlined
                    hide-details
                    append-icon="phút"
                    type="number"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    v-model.number="item.wait_quantity"
                    class="c-input-small c-input-append-text"
                    dense
                    single-line
                    outlined
                    hide-details
                    type="number"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    v-model.number="item.process_quantity"
                    class="c-input-small c-input-append-text"
                    dense
                    single-line
                    outlined
                    hide-details
                    type="number"
                  ></v-text-field>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import {
  WARNING_CONFIG_DEFAULT,
  ITEM_TYPES,
  WARNING_TYPES,
} from "@/libs/const";

export default {
  name: "WarningCreateConfig",
  components: {},
  props: {
    config: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isLoading: false,
    items: [...WARNING_CONFIG_DEFAULT],
    typeOptions: [...WARNING_TYPES],
    itemTypeOptions: [...ITEM_TYPES],
    name: null,
    code: null,
  }),
  computed: {
    isDisabledBtn() {
      if (!this.name) {
        return true;
      }

      const checkItem = [...this.items].find(
        (item) =>
          !item.wait_time ||
          !item.wait_quantity ||
          !item.process_quantity ||
          !item.type
      );
      return !!checkItem;
    },
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    async save() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }

      this.isLoading = true;

      try {
        await httpClient.post("/employee/v1/warning-config-add", {
          items: this.items,
          name: this.name,
        });
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.create_success"));
        this.cancel();
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>

<style scoped></style>
